$(document).ready(function () {

  let contentRight = $('.right-side')

  /**
   * click to open sidebar menu */
  $('#btn-menu').on('click.mobileNav', function () {
    // Toggle the open/closed state of the menu icon
    $('#btn-menu .menu-link').toggleClass('menu-trigger-open');
  });

  $('.search-content').on('focusout', function () {
    contentRight.find('.icon-search').toggleClass('d-none');
    contentRight.find('.search-box').toggleClass('d-none');
  });

  /**
   * Click to open input to search */
  contentRight.on('click', '.icon-search', function () {
    contentRight.find('.icon-search').toggleClass('d-none');
    contentRight.find('.search-box').toggleClass('d-none');

    $("#searchPC").focus();
  });

  /**
   * Click to close input to search */
  contentRight.on('click', ' #close-btn', function () {
    contentRight.find('.icon-search').toggleClass('d-none');
    contentRight.find('.search-box').toggleClass('d-none');
  })

  /**
   * Event key to search */
  $(document).on('keypress', function (event) {
    const target = $(event.target);
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      if (target.prop('id') === 'searchPC') {
        if (target.val() === '') {
          event.preventDefault();
        }
      } else {
        return;
      }
    }

    if (target.is('input') || target.is('textarea')) {
      return;
    }

    $("#searchPC").focus();
  });
});
